import React from "react";
import PhotoStack from "./PhotoStack";

class PhotosItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      items: {
        photos: [],
        totalWidth: 0,
        totalHeight: 0,
      },
    };
  }

  componentDidMount() {
    fetch("https://tjchicoine.ca/etc/API/album/testAlbum")
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            items: {
              photos: result.items,
            },
            isLoaded: true,
          });
        },

        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );
  }

  render() {
    const { error, isLoaded, items } = this.state;
    if (error) {
      return <div>Error: {error.message} </div>;
    } else if (!isLoaded) {
      return <div className="loading PhotoStack">Loading...</div>;
    } else {
      return <PhotoStack items={items}></PhotoStack>;
    }
  }
}

export default PhotosItem;
