import React, { useEffect, useState } from "react";
import { isMobile, isIOS } from "react-device-detect";
import "../css/Manage.css";

let albums = ["India", "Sri Lanka", "Nepal"];

function listAlbums(callback) {
  let testArray = [];
  for (let index = 0; index < 100; index++) {
    let newAlbum = album(index, callback);
    testArray.push(newAlbum);
  }
  return testArray;
  return albums.map((el) => {
    return album(el, callback);
  });
}

function album(name, callback) {
  function editAlbum() {
    callback(name);
  }

  return (
    <div className="album" onClick={editAlbum}>
      <h1>{name}</h1>
      <p>50 Photos</p>
    </div>
  );
}

export default function Manage() {
  let style = null;
  document.body.style.overflow = "hidden";

  if (isIOS && isMobile) {
    style = { height: window.innerHeight - 56 };
  }

  const [editMode, setEditMode] = useState(false);
  const [albumToEdit, setAlbumToEdit] = useState("");

  function callback(name) {
    setAlbumToEdit(name);
    setEditMode(true);
    console.log(name);
  }

  function backToView() {
    setEditMode(false);
  }

  function viewMode() {
    return (
      <>
        <h1>Manage Albums</h1>
        <div className="album-editor">{listAlbums(callback)}</div>
        <div className="manage-btn">
          <h1>New Album</h1>
        </div>
      </>
    );
  }

  function edit() {
    return (
      <>
        <h1>{albumToEdit}</h1>
        <button onClick={backToView}>back</button>
      </>
    );
  }

  return (
    <div className="parent" style={style}>
      <div className="console">{editMode === false ? viewMode() : edit()}</div>
    </div>
  );
}
