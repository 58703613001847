import React from 'react'
import './util.css'
import {ReactComponent as Spinner} from '../svgs/loading.svg'

class LoadingSpinner extends React.Component {
    render() {
        return (
            <div className="center">
                <Spinner height={100} width={100}/>
            </div>
        )
    }
}

export { LoadingSpinner }