import React from "react";
import PhotosItem from "../PhotoItems/PhotosItem";
import "../../css/AlbumItem.css";

class AlbumItem extends React.Component {
  render() {
    return (
      <div className="AlbumItem">
        <div className="AlbumCard">
          <PhotosItem></PhotosItem>
          <div className="disableInteraction AlbumTitle">
            {this.props.title}
          </div>
        </div>
      </div>
    );
  }
}

export default AlbumItem;
