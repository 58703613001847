import React from "react";

class PhotoStack extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      height: 0,
      width: 0,
    };
    this.updateDimensions = this.updateDimensions.bind(this);
  }

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  updateDimensions() {
    const height = this.divContainer.clientHeight;
    const width = this.divContainer.clientWidth;
    this.setState({ height, width });
  }

  renderPhotos(items) {
    let photoList = [];
    let counter = 0;
    let baseOffset = 30;
    let offsetX;
    let style;
    let numberOfPhotos = items.photos.length;

    items.photos.forEach((element) => {
      let offsetY = baseOffset * counter;
      let photoY = this.state.height - baseOffset * 2;
      let photoX = (photoY * 2) / 3;

      if (counter === 0) {
        offsetX = 0;
      } else if (counter === numberOfPhotos - 1) {
        offsetX = this.state.width - photoX;
      } else {
        offsetX = (this.state.width - photoX) / (numberOfPhotos - counter);
      }

      style = {
        height: photoY,
        width: photoX,
        top: offsetY,
        left: offsetX,
      };

      photoList.push(
        <img
          className="photoStackIMG"
          src={element.src}
          style={style}
          key={element.src}
          alt={element.src}
        ></img>
      );

      counter++;
    });
    return photoList;
  }

  render() {
    return (
      <div
        className="PhotoStack"
        ref={(divContainer) => {
          this.divContainer = divContainer;
        }}
      >
        <div className="PhotoStackHolder">{this.renderPhotos(this.props.items)}</div>
      </div>
    );
  }
}

export default PhotoStack;
