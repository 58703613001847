import React from "react";
import "../css/Photos.css"
import SelectableGallery from "./SelectableGallery"
import Albums from "./Albums/Albums"
import { LoadingSpinner } from '../Util/loadingSpinner'



class Photos extends React.Component {
    constructor(props) {
        super(props);
        document.body.style.overflow = "visible"
        this.state = {
            error: null,
            isLoaded: false,
            items: []
        };
    }

    componentDidMount() {
        fetch("https://tjchicoine.ca/etc/API/photos")
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        isLoaded: true,
                        items: result.items
                    });
                },

                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }

    render() {
        const { error, isLoaded, items } = this.state;
        if (error) {
            return <div>Error: {error.message} </div>;
        } else if (!isLoaded) {
            return (
                <LoadingSpinner />
            )
        } else {
            return (
                <SelectableGallery photos={items} />
            );
        }
    }
}

export {
    Albums,
    Photos
}