import React from 'react';
import Home from './components/Home/Home'
import { Photos, Albums } from './components/Photos/Photos'
import About from './components/About/About'
import NotFound from './components/Util/NotFound'
import Navbar from './components/Util/Navbar'


import { Switch, Route } from 'react-router-dom';
import Manage from './components/Manage/Manage';



function App() {

  return (
    <div className = "App">
      <main>
        <Switch>
          <Route path="/" component={Home} exact />
          <Route path="/About" component={About} />
          <Route path="/Albums" component={Albums} />
          <Route path="/Photos" component={Photos} />
          <Route path="/Manage" component={Manage} />
          <Route component={NotFound} />
        </Switch>
      </main>
      <Navbar />
    </div>
  );
}

export default App;
