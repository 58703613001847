import React, { useEffect } from "react";

import "../css/About.css";
import "../css/custom.scss";
import anime from "animejs";
import { ReactComponent as LinesBackground } from "../svgs/lines1.0.svg";
import Carousel from "react-bootstrap/Carousel";
import { isBrowser, isMobile } from "react-device-detect"

const CaroselCard = () => (
  <div className="card card-1">
    <div className="header">
      <h1>Hello it's me</h1>
    </div>
    <div className="body">
      <Carousel nextLabel="" prevLabel="" interval={10000}>
        <Carousel.Item>
          <img
            className="image-style"
            src="https://tjchicoine.ca/etc/photos/web-11.jpg"
            alt="first slide"
          ></img>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="image-style"
            src="https://tjchicoine.ca/etc/photos/web-41.jpg"
            alt="first slide"
          ></img>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="image-style"
            src="https://tjchicoine.ca/etc/photos/web-66.jpg"
            alt="first slide"
          ></img>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="image-style"
            src="https://tjchicoine.ca/etc/photos/web-109.jpg"
            alt="first slide"
          ></img>
        </Carousel.Item>
      </Carousel>
    </div>
    <div className="footer">
      <h3>BIO</h3>
      <p>
        Electrical/Mechanical Engineer by ways of regurgitated
        knowledge under strict time constraints. My day job is programming jet
        engines - make turbine go brrrr. I also learned how to code by repetitively
        hammering a keyboard different ways.
      </p>
    </div>
  </div>
);

function About() {
  document.body.style.overflow = "visible";
  anime.suspendWhenDocumentHidden = false;
  function timelineDetails(id) {
    let target = document.querySelectorAll('[id*="' + id + '"]');
    return {
      targets: target,
      strokeDashoffset: [anime.setDashoffset, 0],
    };
  }

  useEffect(() => {
    var pathEls = document.querySelectorAll('[id*="path"]');
    for (var i = 0; i < pathEls.length; i++) {
      var pathEl = pathEls[i];
      var offset = anime.setDashoffset(pathEl);
      pathEl.setAttribute("stroke-dashoffset", offset);
      anime({
        targets: pathEl,
        strokeDashoffset: [offset, 0],
        duration: anime.random(1000, 3000),
        delay: anime.random(0, 2000),
        loop: true,
        direction: "alternate",
        easing: "easeInOutSine",
        autoplay: true,
      });
    }
    let textIds = ["Tstart", "Ttop", "Tbottom", "J", "C"];
    let textTimeline = anime.timeline({
      easing: "easeInOutSine",
      duration: 3000,
      loop: isMobile ? true : false,
      direction: 'alternate'
    });
    textIds.forEach((partialid) => {
      textTimeline.add(timelineDetails(partialid), "-=1500");
    });

    if (isBrowser) {
      let cards = document.querySelectorAll(".card");
      cards.forEach((card) => {
        var animation = anime({
          targets: card,
          translateY: [anime.random(anime.random(3, 10), -10)],
          translateX: [anime.random(anime.random(3, 10), -10)],
          rotate: [anime.random(-3, 1), anime.random(1, 3)],
          duration: 8000,
          easing: "linear",
          direction: "alternate",
          loop: true,
          autoplay: true,
        });
        card.addEventListener('mouseover', animation.pause)
        card.addEventListener('mouseout', animation.play)
      });
    }
  });

  return (
    <>
      <div className="svgdiv">
        <LinesBackground
          height="100%"
          width="100%"
          preserveAspectRatio="none"
        ></LinesBackground>
      </div>
      <div className="About">
        <div className="About-Contents">
          {CaroselCard()}
          <div className="card card-2">
            <div className="header">
              <h2>G.J (Gym Journal)</h2>
            </div>
            <div className="body body-only">
              <p>
                Built with android SDK using java. Track workout history with
                interactive plate calculator and custom routines.
              </p>
            </div>
          </div>
          <div className="card card-3">
            <div className="header">
              <h2>F.APP</h2>
            </div>
            <div className="body body-only">
              <p>
                Financial.App - built with Laravel. Import CSV's and view how
                much money you are spending unneccesarily.
              </p>
            </div>
          </div>
          <div className="card card-4">
            <div className="header">
              <h2>@MOS</h2>
            </div>
            <div className="body body-only">
              <p>
                Hardware + Software design of distributable weather stations.
              </p>
            </div>
          </div>
          {/* <a
        className="App-link"
        href="/etc/lizard.gif"
        target="_blank"
        rel="noopener noreferrer"
      >
        What's This?
      </a> */}
        </div>
      </div>
    </>
  );
}

export default About;
