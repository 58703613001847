import React, { useEffect } from 'react';
// import logo from './logo.svg';
import { ReactComponent as Logo } from "../svgs/logo.svg";

import { ReactComponent as Insta } from "../svgs/instagram.svg"
import { ReactComponent as LinkedIn } from "../svgs/linkedin.svg"
import { ReactComponent as Github } from "../svgs/github.svg"
import { ReactComponent as FHP } from "../svgs/500px.svg"

import '../css/Home.css';

function Home() {
  document.body.style.overflow = "hidden"

  var ua = window.navigator.userAgent;
  var iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
  var webkit = !!ua.match(/WebKit/i);
  var iOSSafari = iOS && webkit && !ua.match(/CriOS/i) && !ua.match(/Fxi/i)

  useEffect(_ => {
    if (iOSSafari) {
      document.getElementById('app-header').classList.add('apple')
    }else {
      document.getElementById('app-header').classList.add('notApple')
    }
  })

  return (
    <div id="app-header" className="App-header">
      <Logo className="App-logo" />
      <div className="card card-only">
        <div className="bold-body">
          <h1>Occasionally I even</h1>
          <h1>shock myself</h1>
        </div>
      </div>
      <div className="card card-only" style={{ margin: '16px' }}>
        <span className="inline-body">
          <a href="https://instagram.com/tjchicoine"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Insta className="link-svg"></Insta>
          </a>
          <a href="https://ca.linkedin.com/in/tjchicoine"
            target="_blank"
            rel="noopener noreferrer"
          >
            <LinkedIn className="link-svg"></LinkedIn>
          </a>
          <a href="https://github.com/tjchicoine"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Github className="link-svg"></Github>
          </a>
        </span>
      </div>
    </div>
  );
}

export default Home;
