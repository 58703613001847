import React from "react";
import AlbumItem from "./AlbumItem";
import "../../css/Albums.css";
import Fade from "react-reveal/Fade";
import { ReactComponent as Settings } from '../../svgs/setting.svg'
import { Link } from "react-router-dom";

const AlbumNames = ["Nepal", "India", "Sri Lanka", "All Photos"];

class Albums extends React.Component {
  constructor(props) {
    super(props)
    document.body.style.overflow = "visible";
  }
  createAlbumList() {
    let albumItemArray = [];
    AlbumNames.forEach((element) => {
      albumItemArray.push(this.createAlbum(element));
    });

    return albumItemArray;
  }

  createAlbum(albumName) {
    return (
      <Fade>
        <AlbumItem title={albumName} />
      </Fade>
    );
  }

  render() {
    return (
      <div className="parent">
        <div className="disableInteraction title-holder">
          <div className="title">
            Albums
          </div>
          <Link to='/manage'>
            <Settings className='settings-icon'/>
          </Link>
        </div>
        <div className="Albums">{this.createAlbumList()}</div>
      </div>
    );
  }
}

export default Albums;
