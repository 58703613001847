import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css';
import loading from '../svgs/loading.svg'

const LazyImage = ({ index, image, callback}) => (
        <LazyLoadImage
            alt={image.alt}
            height={image.height}
            src={image.src}
            width={image.width}
            className='lazy-image-style'
            effect="opacity"
            threshold={80}
            onClick={() => callback(index)}
            placeholderSrc={loading}
        />
)

export default LazyImage