import React from 'react';
import '../css/navbar.css'
import { ReactComponent as Home } from '../svgs/home-solid.svg'
import { ReactComponent as About } from '../svgs/address-card-solid.svg'
import { ReactComponent as Photos } from '../svgs/images-solid.svg'
import { ReactComponent as Arrow } from '../svgs/angle-double-right-solid.svg'
import { ReactComponent as Albums } from '../svgs/angle-double-right-solid.svg'

import { Link } from "react-router-dom"

const items = [
    { link: '/', linkText: "Home", svg: <Home /> },
    { link: 'Photos', linkText: "Photos", svg: <Photos /> },
    // { link: 'Albums', linkText: 'Albums', svg: <Albums/>},
    { link: 'About', linkText: "About", svg: <About /> }
]

class Navbar extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            isBig: false,
            oldHeight: 0,
        }
    }

    componentDidMount() {
        this.updateDimensions();
        var ua = window.navigator.userAgent;
        var iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
        var webkit = !!ua.match(/WebKit/i);
        var iOSSafari = iOS && webkit && !ua.match(/CriOS/i);
        if (!iOSSafari) {return}
        window.addEventListener("resize", this.updateDimensions.bind(this));
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions.bind(this));
    }

    updateDimensions() {
        const newHeight = window.innerHeight;
        if (this.state.oldHeight === 0) { this.setState({ oldHeight: newHeight }); return}
        const isBig = (this.state.oldHeight < newHeight) ? true : false;
        this.setState({ isBig: isBig, oldHeight: newHeight });
    }

    getLinks() {
        return (
            items.forEach((entry) => {
                NavbarListItem(entry.link, entry.linkText);
            })
        )
    }

    render() {
        const links = items.map((entry) => {
            return (
                <li className="nav-item" key={entry.linkText}>
                    <NavbarListItem
                        link={entry.link}
                        linkText={entry.linkText}
                        svg={entry.svg}
                    />
                </li>
            )
        }
        )
        return (
            <nav className="navbar">
                <ul className="navbar-nav">
                    <li className="logo" key="LOGO">
                        <a href="/" className="nav-link">
                            <Arrow />
                            <span className="link-text logo-text">T.J.C</span>
                        </a>
                    </li>
                    {links}
                </ul>
                {this.state.isBig ? <div style={{ height: 15 }}></div> : <div style={{ height: 0 }}></div>}
            </nav>
        )
    }
}

function NavbarListItem(props) {
    return (
        <Link className="nav-link" to={props.link}>
            {props.svg}
            <span className="link-text">{props.linkText}</span>
        </Link>
    )
}

export default Navbar