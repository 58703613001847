import React from 'react';

import '../css/notfound.css'

function NotFound() {
    return (
        <div className='NotFoundDiv'>
            <h1>Whoops 404</h1>
        </div>
    )
}

export default NotFound