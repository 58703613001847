import React from "react";
import Gallery from "react-photo-gallery"
import Carousel, { Modal, ModalGateway } from "react-images";

import LazyImage from './LazyImage'

class SelectableGallery extends React.Component {
    constructor(props) {
        super(props);
        this.photos = props.photos;
        this.state = {
            currentImage: 0,
            viewerIsOpen: false
        }
    }

    openLightBox = (index) => {
        let newState
        
        if (index !== this.state.currentImage){
            this.setState({currentImage: index})
        } else {
            this.setState({viewerIsOpen: true})   
        }
        this.setState(newState);
    };

    renderLazyLoad = ({index, photo}) => (
        <LazyImage index={index} image={photo} callback={this.openLightBox}></LazyImage>
    )

    closeLightBox = () => {
        let newState = {
            currentImage: 0,
            viewerIsOpen: false
        }
        this.setState(newState);
    };

    render() {
        const currentState = this.state;
        const modalIsOpen = currentState.viewerIsOpen;
        const currentImage = currentState.currentImage;
        return (
            <div className = "photoGallery">
                <Gallery photos={this.photos} renderImage={this.renderLazyLoad}/>
                <ModalGateway>
                    {modalIsOpen ? (
                        <Modal onClose={this.closeLightBox}>
                            <Carousel
                                currentIndex={currentImage}
                                views={this.photos}
                            />
                        </Modal>
                    ) : null}
                </ModalGateway>
            </div>
        )
    }

}

export default SelectableGallery;